import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Field, Form as FinalForm } from 'react-final-form';

import config from '../../config';
import { abuseWords } from '../../marketplace-custom-config';
import { createSlug } from '../../util/urlHelpers';
import * as validators from '../../util/validators';
import { withSocket } from '../../util/contextHelpers';
import { calculateBiddingAcorns } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';

import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldTextInput,
  FieldRadioButton,
  FieldCurrencyInput,
  NamedLink,
  IconClose,
  DragAndDrop,
  ResponsiveImage,
  IconProfileSetup,
  IconCardDashboard,
} from '../../components';

import css from './EventDetailForm.module.css';

const ACCEPT_FILES = 'image/*, .pdf,.docs';

const EventDetailFormComponent = props => {
  const {
    event,
    socket,
    history,
    categories,
    currentUser,
    onUpdateProfile,
    onRemoveListingFile,
    onRequestFileUpload,
    onFindOrCreateChannel,
    handleQuoteCheckChange,
  } = props;

  const onFileUploadHandler = (files) => onRequestFileUpload(files);

  return (
    <FinalForm
      {...props}
      keepDirtyOnReinitialize={true}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          handleSubmit,
          inProgress,
          intl,
          form,
          values,
          files,
          invalid,
          pristine,
          requirements,
          CurrentAcorns,
          uploadInProgress,
        } = fieldRenderProps;

        const { publicData } = (currentUser && currentUser.id && currentUser.attributes.profile) || {};
        const { acceptPayment, introMessage } = publicData || {};
        const { acorns, winningAcorns, requirementQuotes } = calculateBiddingAcorns(event, values);

        const quoteLabel = intl.formatMessage({
          id: 'EventDetailForm.quoteLabel',
        });
        const quoteLRequired = validators.required(intl.formatMessage({
          id: 'EventDetailForm.quoteRequired',
        }));
        const optionalNoteLabel = intl.formatMessage({
          id: 'EventDetailForm.optionalNoteLabel',
        });

        const acceptPaymentLabel = intl.formatMessage({
          id: 'EventDetailForm.acceptPaymentLabel',
        });
        const acceptPaymentRequired = validators.required(intl.formatMessage({
          id: 'EventDetailForm.acceptPaymentRequired',
        }));
        const introMessageLabel = intl.formatMessage({
          id: 'EventDetailForm.introMessageLabel',
        });
        const introMessageRequired = validators.required(intl.formatMessage({
          id: 'EventDetailForm.introMessageRequired',
        }));
        const bidCheckRequired = validators.required(intl.formatMessage({
          id: 'EventDetailForm.checkMessageRequired',
        }));

        const vendorMessageLabel = intl.formatMessage({
          id: 'EventDetailForm.vendorMessageLabel',
        });
        const vendorMessageRequired = validators.required(intl.formatMessage({
          id: 'EventDetailForm.vendorMessageRequired',
        }));
        const vendorMessageMaxLength = validators.maxLength(intl.formatMessage({
          id: 'EventDetailForm.vendorMessageMaxLength',
        }, {
          maxLength: 500,
        }), 500);

        const classes = classNames(rootClassName || css.root, className);

        const submitInProgress = inProgress;
        const vendorMessage = (values.vendorMessageCount && values.vendorMessageCount > 3) || (values.vendorMessage && abuseWords.findIndex(str => values.vendorMessage.search(str) > -1) > -1);
        const submitDisabled = invalid || submitInProgress || !(values.quoteCheck) || !values.winningBid || (requirementQuotes && Object.keys(requirementQuotes).length == 0);

        useEffect(() => {
          handleQuoteCheckChange(values.quoteCheck);
        }, [values.quoteCheck]);

        const chooseFileLabel = (
          <span className={css.chooseImageText}>
            <IconProfileSetup type={'uploadImage'} />
            <span className={css.chooseImagedesktop}>

              <FormattedMessage id="EventDetailForm.PdfprofilePicAltText" />
            </span>
            {/* Drop or Click to Add Photos or PDF */}
          </span>
        );

        const renderPreview = (id, file) => {
          const isImage = file.type.startsWith('image');

          return <div key={id} className={css.filePreview}>
            {isImage
              ? <div>
                <ResponsiveImage
                  url={URL.createObjectURL(file)}
                  alt={file.name}
                />
                <div className={css.removeItem} onClick={() => onRemoveListingFile({ id, file })}>
                  <IconClose size="normal" className={css.removeIcon} />
                </div>
              </div>
              : <div className={css.pdfPreview}>
                {/* Assuming you have font-awesome or similar */}
                <a target="_blank" href={URL.createObjectURL(file)}>
                  {file.name}
                </a>
                <div className={css.removeItem} onClick={() => onRemoveListingFile({ id, file })}>
                  <IconClose size="normal" className={css.removeIcon} />
                </div>
              </div>}
          </div>;
        };

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.eventDetailsForm}>
              {categories && categories.length && categories.map((category, ind) =>
                <div key={ind} className={css.categoryContainer}>
                  <br />
                  <FieldCheckbox
                    key={"requirement_" + category.label}
                    id={"requirement_" + category.label}
                    className={css.eventCheckBox}
                    name={category.key + "_requirements"}
                    label={<h3>{category.label}</h3>}
                    // value={category.label}
                    onChecked={(e) => {
                      if (e.target.checked) {
                        if (requirements && requirements.length == 0) {
                          form.batch(() => { category.subCategory.map(subCat => form.change(subCat.key + "_requirement", true)) });
                        } else {
                          form.batch(() => {
                            category.subCategory.map(subCat => {
                              const requirements = Object.keys(subCat.requirements).filter(requirement => requirement.includes('requirement-request'));
                              form.change(subCat.key + "_requirement", true);
                              form.change(subCat.key + "_requirements", requirements.map(r => subCat.requirements[r]));
                            })
                          });
                        }
                      } else {
                        if (requirements && requirements.length == 0) {
                          form.batch(() => { category.subCategory.map(subCat => form.change(subCat.key + "_requirement", false)) });
                        } else {
                          form.batch(() => {
                            category.subCategory.map(subCat => {
                              form.change(subCat.key + "_requirement", false)
                              form.change(subCat.key + "_requirements", []);
                            })
                          });
                        }
                      }
                    }}
                  />
                  {category.subCategory && category.subCategory.map((subCat, index) => {
                    const requirements = Object.keys(subCat.requirements).filter(requirement => requirement.includes('requirement-request'));
                    const subProps = { key: index };
                    if (ind) Object.assign(subProps, { className: css.subCategoryContainer });

                    return (<div {...subProps}>
                      {/* Subcategory title */}
                      <div className={css.requirementContainer}>
                        {requirements && requirements.length == 0
                          ? <FieldCheckbox
                            key={"requirement_" + subCat.label}
                            id={"requirement_" + subCat.label}
                            name={subCat.key + "_requirements"}
                            label={<h3>{subCat.label}</h3>}
                            value={subCat.label}
                            className={css.eventCheckBox}
                          />
                          : <FieldCheckbox
                            key={"requirement_" + subCat.label}
                            id={"requirement_" + subCat.label}
                            name={subCat.key + "_requirement"}
                            label={<h3>{subCat.label}</h3>}
                            onChecked={(e) => {
                              if (e.target.checked) {
                                form.change(subCat.key + "_requirements", requirements.map(r => subCat.requirements[r]));
                              } else {
                                form.change(subCat.key + "_requirements", []);
                              }
                            }}
                            className={css.eventCheckBox}
                          />}

                        <div className={css.requirementBox}>
                          {/* Budget */}
                          <div className={css.requirementSection}>
                            <span className={css.icon}>
                              <IconCardDashboard type="dollar" />
                            </span>
                            <span><FormattedMessage id="EventRequest.budget" /></span>
                            <span className={css.boldText}>
                              {subCat.requirements.minPrice && subCat.requirements.minPrice
                                ? 'Max: $' + (subCat.requirements.maxPrice / 100) + ', Min: $' + (subCat.requirements.minPrice / 100)
                                : 'N/A'}
                            </span>
                          </div>

                          {/* Logistics */}
                          <div className={css.requirementSection}>
                            <span className={css.icon}>
                              <IconCardDashboard type="delevery" />
                            </span>
                            <span><FormattedMessage id="EventRequest.logistics" /></span>
                            <span className={css.boldText}>
                              <FormattedMessage id={subCat.requirements.isDelivery
                                ? "EventRequest.deliveryRequired"
                                : "EventRequest.deliveryNotRequired"} />
                            </span>
                          </div>
                        </div>

                        {/* Requirements - Checkboxes */}
                        {requirements && requirements.length
                          ? <>
                            <h2><FormattedMessage id="EventDetailForm.requirementsHeading" /></h2>
                            <div className={css.checkboxWrapper}>
                              {requirements.map(requirement => <FieldCheckbox
                                key={"requirement_" + subCat.requirements[requirement]}
                                id={"requirement_" + subCat.requirements[requirement]}
                                name={subCat.key + "_requirements"}
                                label={subCat.requirements[requirement]}
                                value={subCat.requirements[requirement]}
                                onChecked={(e) => {
                                  if (e.target.checked && values[subCat.key + "_requirements"] && values[subCat.key + "_requirements"].length == (requirements.length - 1)) {
                                    form.change(subCat.key + "_requirement", true);
                                  } else {
                                    form.change(subCat.key + "_requirement", false);
                                  }
                                }}
                                className={css.eventCheckBox}
                              />)}
                            </div>
                          </>
                          : <br />}
                      </div>

                      {values[subCat.key + "_requirements"] && values[subCat.key + "_requirements"].length
                        ? <div className={css.quoteCatInputs}>
                          <FieldCurrencyInput
                            className={css.inputBox}
                            id={subCat.key + "_quote"}
                            name={subCat.key + "_quote"}
                            label={quoteLabel}
                            currencyConfig={config.currencyConfig}
                            validate={values.quote ? null : quoteLRequired}
                          />
                        </div>
                        : null}
                    </div>);
                  }
                  )}
                </div>)}

              <div className={css.detailRadio}>
                {requirements && requirements.length && Array.isArray(requirements)
                  ? <FieldRadioButton
                    className={css.radioBox}
                    id="submitProposal"
                    name="quoteCheck"
                    label={intl.formatMessage({ id: 'EventDetailForm.submitProposal' })}
                    value="submitProposal"
                    showAsRequired={pristine}
                  />
                  : null}
                <FieldRadioButton
                  className={css.radioBox}
                  id="requestMoreInfo"
                  name="quoteCheck"
                  label={intl.formatMessage({ id: 'EventDetailForm.requestMoreInformation' })}
                  value="requestMoreInfo"
                  checkedClassName={css.checkedAvailable}
                  showAsRequired={pristine}
                />
              </div>

              {values.quoteCheck == "submitProposal"
                ? <div>
                  <div className={css.quoteBuilder}>
                    <h4><FormattedMessage id="EventDetailForm.quoteBuilder" /></h4>
                    <div className={css.quoteInputs}>
                      <div className={css.uploadBoxLeft}>
                        {/* <FieldCurrencyInput
                          className={css.inputBox}
                          id="quote"
                          name="quote"
                          label={quoteLabel}
                          currencyConfig={config.currencyConfig}
                          validate={Object.keys(requirementQuotes).length ? null : quoteLRequired}
                        /> */}
                        <div className={css.documentUpload}>
                          <h4><FormattedMessage id="EventDetailForm.optionalAttachments" /> </h4>
                          <div className={css.imageUploadWrapper}>
                            <DragAndDrop
                              dragdrop={css.thumbnail}
                              handleDrop={files => onFileUploadHandler(files)}
                            >
                              <Field
                                accept={ACCEPT_FILES}
                                id="uploadFile"
                                name="uploadFile"
                                label={chooseFileLabel}
                                type="file"
                                form={null}
                                disabled={uploadInProgress}
                              >
                                {fieldProps => {
                                  const { accept, id, input, label, disabled } = fieldProps;
                                  const { name, type } = input;
                                  const onChange = e => onFileUploadHandler(e.target.files);

                                  const inputProps = { accept, id, name, onChange, type, disabled };
                                  return (
                                    <div className={css.addImageWrapper}>
                                      <div className={css.aspectRatioWrapper}>
                                        <label className={css.addImage} htmlFor={id}>
                                          {label}
                                        </label>
                                        <input {...inputProps} multiple className={css.addImageInput} />
                                      </div>
                                    </div>
                                  );
                                }}
                              </Field>
                            </DragAndDrop>
                            {files.map(({ id, file }) => renderPreview(id, file))}
                          </div>
                        </div>
                      </div>
                      <div className={css.optionalNote}>
                        <FieldTextInput
                          id="optionalNote"
                          name="optionalNote"
                          className={css.description}
                          type="textarea"
                          label={optionalNoteLabel}
                        // placeholder={descriptionPlaceholderMessage}
                        // validate={composeValidators(required(descriptionRequiredMessage))}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className={css.addQuoteOption}>
                    <div
                      className={css.link}
                      onClick={() => {
                        const additionalInfo = JSON.parse(JSON.stringify(values.additionalInfo));
                        additionalInfo.push({
                          acceptPayment: "",
                          introMessage: ""
                        });
                        form.change('additionalInfo', additionalInfo);
                      }}
                    >
                      <span><IconProfileSetup type="plus" /></span>
                      <FormattedMessage id="EventDetailForm.addAnotherQuoteOption" />
                    </div>
                  </div> */}
                  {values.additionalInfo && values.additionalInfo.length
                    ? values.additionalInfo.map((ai, i) => (<div className={css.additionalInformation} key={"additionalInfo" + i}>
                      <div>
                        <h4><FormattedMessage id="EventDetailForm.additionalInformation" /> </h4>
                        {values.additionalInfo && values.additionalInfo.length > 1
                          ? <div
                            className={css.link}
                            onClick={() => {
                              const additionalInfo = JSON.parse(JSON.stringify(values.additionalInfo));
                              form.change('additionalInfo', additionalInfo.filter((ain, ind) => i != ind));
                            }}
                          >
                            <FormattedMessage id="EventDetailForm.crossWord" />
                          </div>
                          : null}
                      </div>
                      <div className={css.informationInput}>
                        <label><FormattedMessage id="EventDetailForm.acceptPaymentLabel" /></label>
                        <p className={css.acceptPaymentSubtitle}>
                          <FormattedMessage id="EventDetailForm.acceptPaymentLabelSubtitle" />
                        </p>
                        <FieldTextInput
                          id={"acceptPayment" + (i + 1)}
                          name={"acceptPayment" + (i + 1)}
                          className={css.informationTextarea}
                          type="textarea"
                          label={''}
                        // validate={acceptPaymentRequired}
                        />
                        <div className={css.defaultLink}>
                          {values["acceptPayment" + (i + 1)] == acceptPayment
                            ? null
                            : <div className={css.link} onClick={() => onUpdateProfile({
                              publicData: {
                                acceptPayment: values["acceptPayment" + (i + 1)]
                              }
                            })}>
                              <FormattedMessage id="EventDetailForm.saveDefault" />
                            </div>}
                        </div>
                      </div>
                      <div className={css.introductionInput}>
                        <FieldTextInput
                          id={"introMessage" + (i + 1)}
                          name={"introMessage" + (i + 1)}
                          className={css.introductionTextarea}
                          type="textarea"
                          label={introMessageLabel}
                        // validate={introMessageRequired}
                        />
                        <div className={css.defaultLink}>
                          {values["introMessage" + (i + 1)] == introMessage
                            ? null
                            : <div className={css.link} onClick={() => onUpdateProfile({
                              publicData: {
                                introMessage: values["introMessage" + (i + 1)]
                              }
                            })}>
                              <FormattedMessage id="EventDetailForm.saveDefault" />
                            </div>}
                        </div>
                      </div>
                    </div>))
                    : null}

                  <div>
                    <FieldCheckbox
                      key={"winningBid"}
                      id={"winningBid"}
                      name={"winningBid"}
                      label={intl.formatMessage({ id: "EventDetailForm.winningAcorns" }, { winningAcorns })}
                      className={css.eventCheckBox}
                      validate={bidCheckRequired}
                    />
                  </div>

                  <div className={css.bottomButton}>
                    {(acorns ? (CurrentAcorns < acorns) : (CurrentAcorns < 5))
                      ? <PrimaryButton
                        type={'button'}
                        inProgress={submitInProgress}
                        onClick={() => {
                          typeof window != 'undefined' && window.sessionStorage.setItem('PurchaseAcornsPage', JSON.stringify({
                            txId: event.txId,
                            slug: createSlug(event.title),
                            tab: 'request',
                            values
                          }));
                          history.push(createResourceLocatorString('PurchaseAcornsPage', routeConfiguration(), {}));
                        }}
                      >
                        <FormattedMessage id="EventDetailForm.purchaseAcorns" />
                      </PrimaryButton>
                      : <PrimaryButton
                        type={'submit'}
                        inProgress={submitInProgress}
                        disabled={submitDisabled}
                      >
                        <FormattedMessage id="EventDetailForm.submitMyBid" />
                        {acorns
                          ? <FormattedMessage id="EventDetailForm.Acorns" values={{ biddingAcorns: acorns }} />
                          : ''}
                      </PrimaryButton>}
                  </div>
                </div>
                : values.quoteCheck == 'requestMoreInfo'
                  ? <div>
                    <FieldTextInput
                      className={css.professionalBioRoot}
                      type="textarea"
                      id="vendorMessage"
                      name="vendorMessage"
                      label={vendorMessageLabel}
                      validate={validators.composeValidators(vendorMessageRequired, vendorMessageMaxLength)}
                    />
                    <div className={css.bottomButton}>
                      {socket
                        ? <PrimaryButton
                          type={'button'}
                          inProgress={submitInProgress}
                          disabled={vendorMessage} //|| submitDisabled
                          onClick={(e) => {
                            e.preventDefault();
                            if (!values.vendorMessage) return;
                            handleSubmit(values)
                              .then(res => {

                                onFindOrCreateChannel({
                                  senderId: currentUser.id.uuid,
                                  receiverId: event.eventOwner.id,
                                  type: 'transactional',
                                  tab: event.txId,
                                  eventId: event.eventId,
                                  slug: createSlug(event.title),
                                })
                                  .then(channel => {

                                    socket.emit('message', {
                                      body: values.vendorMessage,
                                      type: 'text',
                                      seen: false,
                                      channel: channel._id,
                                      channelId: channel.channelId,
                                      sender: currentUser.id.uuid,
                                      receiver: event.eventOwner.id,
                                    });
                                    if (typeof window !== 'undefined') {
                                      window.sessionStorage.setItem('channelId', channel.channelId);
                                    }

                                    return history.push(createResourceLocatorString(
                                      'ProposalTabPage', routeConfiguration(), {
                                      txId: event.txId,
                                      slug: createSlug(event.title),
                                      tab: 'messages'
                                    }));
                                  });
                              });
                          }}
                        >
                          <FormattedMessage id="EventDetailForm.submit" />
                        </PrimaryButton>
                        : null}
                    </div>
                  </div>
                  : null}
            </div>

          </Form>
        );
      }}
    />
  )
};

EventDetailFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  formId: null,
};

const { string, bool, func } = PropTypes;

EventDetailFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  intl: intlShape.isRequired,
  formId: string,
};

const EventDetailForm = compose(injectIntl, withRouter, withSocket)(EventDetailFormComponent);
EventDetailForm.displayName = 'EventDetailForm';

export default EventDetailForm;
