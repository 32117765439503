import moment from "moment";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { createChannel, fetchTotalUnreadsCount, getChannels, getMessages, markAllQuotesAsRead } from "../../util/api";

// ================ Action types ================ //
export const GET_CHANNELS_REQUEST = 'app/ChatPage/GET_CHANNELS_REQUEST';
export const GET_CHANNELS_SUCCESS = 'app/ChatPage/GET_CHANNELS_SUCCESS';
export const GET_CHANNELS_ERROR = 'app/ChatPage/GET_CHANNELS_ERROR';

export const UPLOAD_MEDIA_REQUEST = 'app/ChatPage/UPLOAD_MEDIA_REQUEST';
export const UPLOAD_MEDIA_SUCCESS = 'app/ChatPage/UPLOAD_MEDIA_SUCCESS';
export const UPLOAD_MEDIA_ERROR = 'app/ChatPage/UPLOAD_IMAGE_ERROR';

export const GET_MESSAGES_REQUEST = 'app/ChatPage/GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'app/ChatPage/GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_ERROR = 'app/ChatPage/GET_MESSAGES_ERROR';

export const CREATE_CHANNEL_REQUEST = 'app/ChatPage/CREATE_CHANNEL_REQUEST';
export const CREATE_CHANNEL_SUCCESS = 'app/ChatPage/CREATE_CHANNEL_SUCCESS';
export const FETCH_INVOICE_SUCCESS = 'app/ChatPage/FETCH_INVOICE_SUCCESS';
export const CREATE_CHANNEL_ERROR = 'app/ChatPage/CREATE_CHANNEL_ERROR';

export const FETCH_TOTAL_UNREADS_SUCCESS = 'app/ChatPage/FETCH_TOTAL_UNREADS_SUCCESS';
export const FETCH_TOTAL_UNREADS_REQUEST = 'app/ChatPage/FETCH_TOTAL_UNREADS_REQUEST';

// ================ Reducer ================ //

const initialState = {
    channels: [],
    invoices: [],
    transactions: [],
    getChannelsInProgress: false,
    getChannelsError: null,

    channel: null,
    createChannelInProgress: false,
    createChannelError: null,

    // for message limit... not implemented yet
    messages: {},
    getMessagesInProgress: false,
    getMessageError: null,

    uploadMediaInProgress: false,
    uploadMediaSuccess: null,
    uploadMediaError: null,

    quotes: null,
    totalunreadcount: null,
    totalunreadcountInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {

        case CREATE_CHANNEL_REQUEST:
            return { ...state, createChannelError: null, createChannelInProgress: true, channel: null }
        case CREATE_CHANNEL_SUCCESS:
            return {
                ...state,
                createChannelError: null,
                createChannelInProgress: false,
                channel: { ...payload.channel, unreadCount: 0 },
                channels: payload.markAllAsRead ?
                    state.channels.map(channel => {
                        if (channel._id === payload.channel._id) {
                            return {
                                ...channel,
                                unreadCount: 0
                            }
                        }
                        return channel;
                    }) : state.channels,
                totalunreadcount: payload.channel?.unreadCount ? Math.max(0, state.totalunreadcount - payload.channel.unreadCount) : state.totalunreadcount
            };
        case CREATE_CHANNEL_ERROR:
            return { ...state, createChannelError: payload, createChannelInProgress: false, channel: null }

        case GET_CHANNELS_REQUEST:
            return { ...state, getChannelsInProgress: true, getChannelsError: null, channels: [], transactions: [], invoices: [] };
        case FETCH_INVOICE_SUCCESS:
            return {
                ...state,
                invoices: payload.invoices,
                transactions: payload.transactions,
            };
        case GET_CHANNELS_SUCCESS:
            return {
                ...state,
                getChannelsInProgress: false,
                getChannelsError: null,
                channels: payload,
                totalunreadcount: Array.isArray(payload) ? payload.reduce((total, ch) => ch.unreadCount + total, 0) : 0
            };
        case GET_CHANNELS_ERROR:
            return { ...state, getChannelsInProgress: false, getChannelsError: payload, channels: [], transactions: [], invoices: [] };

        case FETCH_TOTAL_UNREADS_REQUEST:
            return { ...state, totalunreadcountInProgress: true };
        case FETCH_TOTAL_UNREADS_SUCCESS: {
            let quotes = state.quotes;
            let messages = state.messages;
            if (payload.quotes && payload.quotes.length) {
                quotes = payload.quotes;
            }
            if (payload.messages && payload.messages.length) {
                messages = payload.messages;
            }
            return { ...state, messages, quotes, totalunreadcountInProgress: false };
        }

        case GET_MESSAGES_REQUEST:
            return { ...state, getMessagesInProgress: true, getMessageError: null };
        case GET_MESSAGES_ERROR:
            return { ...state, getMessagesInProgress: false, getMessageError: payload };
        case GET_MESSAGES_SUCCESS: {
            const { threadId, messages } = payload;
            return {
                ...state,
                getMessagesInProgress: false,
                getMessageError: null,
                messages: {
                    ...state.messages,
                    [threadId]: messages
                },
            };
        }
        default:
            return state;
    }
}

// ================ Action creators ================ //

const createChannleInProgress = () => ({
    type: CREATE_CHANNEL_REQUEST
})

const fetchInvoiceSuccess = (transactions = [], invoices = []) => ({
    type: FETCH_INVOICE_SUCCESS,
    payload: { transactions, invoices }
});

export const createChannelSuccess = (channel, markAllAsRead = false,) => ({
    type: CREATE_CHANNEL_SUCCESS,
    payload: { channel, markAllAsRead }
});

const createChannelError = (e) => ({
    type: CREATE_CHANNEL_ERROR,
    payload: e
})

const getChannelsInProgress = () => ({
    type: GET_CHANNELS_REQUEST
})

const getChannelsError = (e) => ({
    type: GET_CHANNELS_ERROR,
    payload: e
})

export const getChannelsSuccess = (channels) => ({
    type: GET_CHANNELS_SUCCESS,
    payload: channels
})

const getMessagesInProgress = () => ({
    type: GET_MESSAGES_ERROR
})

const getMessagesError = (e) => ({
    type: GET_MESSAGES_ERROR,
    payload: e
})

const getMessagesSuccess = (payload) => ({
    type: GET_MESSAGES_SUCCESS,
    payload
})

const fetchTotalUnreadsRequest = () => ({
    type: FETCH_TOTAL_UNREADS_REQUEST
})

export const fetchTotalUnreadsSuccess = (payload) => ({
    type: FETCH_TOTAL_UNREADS_SUCCESS,
    payload
})

// ================ Thunk ================ //

export const findOrCreateChannel = params => (dispatch, getState, sdk) => {
    dispatch(createChannleInProgress());

    return createChannel(params)
        .then(channel => {
            dispatch(createChannelSuccess(channel));

            if (params.type == 'transactional') {
                dispatch(fetchTransactionsAndChannels({ tab: params.tab }));
            }

            return channel;
        })
        .catch(e => {
            dispatch(createChannelError(e));
            return e;
        })
}

export const fetchAllMessages = params => (dispatch, getState, sdk) => {
    dispatch(getMessagesInProgress());
    return getMessages(params)
        .then(messages => {
            dispatch(getMessagesSuccess({ threadId: params.threadId, messages }))
        })
        .catch(e => {
            dispatch(getMessagesError(e));
        })
}

export const addMessage = (params, fromDifferentChannel = false) => (dispatch, getState) => {
    const { channel: _id } = params;

    const { channels = [], channel = {} } = getState().ChatPage;

    const updatedChannelIndex = channels.findIndex(chanel => chanel._id === _id);
    if (updatedChannelIndex > -1) {
        const updatedChannel = channels[updatedChannelIndex];
        if (channel && channel._id == updatedChannel._id && channel.isUnlocked) {
            Object.assign(updatedChannel, { isUnlocked: channel.isUnlocked });
        }
        if (params && !params.createdAt) {
            Object.assign(params, {
                createdAt: moment().toDate()
            });
        }

        updatedChannel.chats = [...(channel.chats ?? []), params];

        channels[updatedChannelIndex] = updatedChannel;

        if (fromDifferentChannel) {
            dispatch(getChannelsSuccess(channels.map(chanel =>
                chanel._id === _id ?
                    { ...chanel, unreadCount: chanel.unreadCount + 1 }
                    : chanel)))
        } else {
            dispatch(getChannelsSuccess(channels));
        }

        if (channel._id === updatedChannel._id) {
            dispatch(createChannelSuccess(updatedChannel));
        }
    }

}

export const fetchTotalUnreads = (params) => (dispatch, getState) => {

    const { totalunreadcountInProgress, totalunreadcount } = getState().ChatPage;
    if (totalunreadcountInProgress || totalunreadcount != null) return Promise.resolve([]);

    dispatch(fetchTotalUnreadsRequest());
    return fetchTotalUnreadsCount(params)
        .then(response => {
            dispatch(fetchTotalUnreadsSuccess(response))
        })
        .catch(err => console.error(err, '**** **** => err'));
}

export const clearAllQuotes = (params) => (dispatch, getState) => {
    dispatch(fetchTotalUnreadsRequest());

    return markAllQuotesAsRead(params)
        .then(response => {
            dispatch(fetchTotalUnreadsSuccess(response))
        })
        .catch(err => console.error(err, '**** **** => err'));
}

export const fetchTransactionsAndChannels = (params) => (dispatch, getState) => {
    let { currentUser } = getState().user;

    if (!currentUser || !(currentUser && currentUser.id)) {
        currentUser = dispatch(fetchCurrentUser());
    }
    const channelId = typeof window !== 'undefined' && window.sessionStorage.getItem('channelId');

    if (currentUser && currentUser.id) {
        dispatch(getChannelsInProgress());
        const payload = {
            userId: currentUser.id.uuid,
            type: (params && params.tab ? 'transactional' : 'chat')
        };
        if (params && params.tab) {
            Object.assign(payload, { tab: params.tab });
        }

        return getChannels(payload)
            .then(({ channels, transactions, invoices }) => {
                dispatch(getChannelsSuccess(channels));
                dispatch(fetchInvoiceSuccess(transactions, invoices));
                if (channels && channels.length) {
                    if (params && params.tab) {
                        const channel = channels.find(ch => ch.tab === params.tab);
                        return dispatch(createChannelSuccess(channel, false));
                    } else if (channelId) {
                        const channel = channels.find(ch => ch.channelId === channelId);
                        if (channel) {
                            // typeof window !== 'undefined' && window.sessionStorage.removeItem('channelId');
                            return dispatch(createChannelSuccess(channel, false));
                        } else {
                            return dispatch(createChannelSuccess(channels[0], false));
                        }
                    }
                    return dispatch(createChannelSuccess(channels[0], false));
                }
                return dispatch(createChannelSuccess({}, false));
            })
            .catch(e => {
                dispatch(getChannelsError(e));
            })
    }
    return Promise.all([]);
};

export const loadData = params => (dispatch) => {
    return dispatch(fetchTransactionsAndChannels(params));
}